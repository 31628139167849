.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.Item {
  transition: all 0.2s ease-in-out;
  position: relative;
}

.Item:hover {
  /* width: 200px; */
  /* height: 200px; */
  transform: scale(8);
  border-radius: 2px;
  /* z-index: 100; */
}

.SideBarItem {
  transition: all 0.1s ease-in-out;
}

.SideBarItem:hover {
  /* width: 200px; */
  /* height: 200px; */
  transform: scale(1.05);
  border: 5px solid red;

  /* border-radius: 2px; */
  /* z-index: 100; */
}

.SideItem {
  transition: all 0.15s ease-in-out;
}

.SideItem:hover {
  transform: scale(1.03);
  /* border-bottom-width: 50px; */
  border: 5px solid red;
  /* border-bottom-color: red; */
}

.FocusedItem {
  transition: all 0.15s ease-in-out;
}

.FocusedItem:hover {
  transform: scale(1.05);
  border-bottom: 1px solid red;
}